<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4571 14.9571C17.0666 15.3476 16.4334 15.3476 16.0429 14.9571L12 10.9142L7.95711 14.9571C7.56658 15.3476 6.93342 15.3476 6.5429 14.9571C6.15237 14.5666 6.15237 13.9334 6.54289 13.5429L11.2858 8.79996C11.6803 8.40551 12.3198 8.40556 12.7142 8.79996L17.4571 13.5429C17.8476 13.9334 17.8476 14.5666 17.4571 14.9571Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
    fill: { type: String, required: false, default: 'currentColor' },
  },
};
</script>
