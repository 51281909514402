<template>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 1L3.07586 6.88621C3.03832 6.94252 2.95685 6.94606 2.91457 6.89321L1 4.5"
      :stroke="fill"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
  },
};
</script>
