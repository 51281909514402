<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.54289 9.04289C6.93342 8.65237 7.56658 8.65237 7.95711 9.04289L12 13.0858L16.0429 9.04289C16.4334 8.65237 17.0666 8.65237 17.4571 9.04289C17.8476 9.43342 17.8476 10.0666 17.4571 10.4571L12.7142 15.2C12.3197 15.5945 11.6802 15.5944 11.2858 15.2L6.54289 10.4571C6.15237 10.0666 6.15237 9.43342 6.54289 9.04289Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
    fill: { type: String, required: false, default: 'currentColor' },
  },
};
</script>
