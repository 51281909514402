<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.8321 8.5547C17.1384 8.09517 17.0142 7.4743 16.5547 7.16795C16.0952 6.8616 15.4743 6.98577 15.1679 7.4453L10.5775 14.331L8.76822 12.1598C8.41466 11.7355 7.78409 11.6782 7.35982 12.0318C6.93554 12.3853 6.87821 13.0159 7.23178 13.4402L9.89845 16.6402L10.7558 17.669L11.4987 16.5547L16.8321 8.5547Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
