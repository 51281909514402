<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 5L19 19M5 19L19 5" :stroke="color" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  props: {
    color: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
