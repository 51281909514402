<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.14625 12C7.14625 9.31935 9.31935 7.14625 12 7.14625C14.6807 7.14625 16.8537 9.31935 16.8537 12C16.8537 14.6807 14.6807 16.8537 12 16.8537C9.31935 16.8537 7.14625 14.6806 7.14625 12ZM10.3215 13.6991C10.7732 14.1497 11.3859 14.4028 12.0248 14.4028V9.59715C11.3859 9.59715 10.7732 9.8503 10.3215 10.3009C9.86973 10.7515 9.61594 11.3627 9.61594 12C9.61594 12.6373 9.86973 13.2484 10.3215 13.6991Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.34615 2H21.6539C21.845 2 22 2.15497 22 2.34615V21.6539C22 21.845 21.845 22 21.6539 22H2.34615C2.15498 22 2 21.845 2 21.6539V2.34615C2 2.15497 2.15498 2 2.34615 2ZM12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
