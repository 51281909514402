<template>
  <svg
    v-if="direction === 'down'"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 10L12 14L16 10" :stroke="fill" stroke-width="1.5" />
  </svg>
  <svg
    v-else-if="direction === 'up'"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 14L12 9L7 14" :stroke="fill" stroke-width="1.5" />
  </svg>

  <svg
    v-else-if="direction === 'left'"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 7L9 12L14 17" :stroke="fill" stroke-width="1.5" />
  </svg>
  <svg
    v-else-if="direction === 'right'"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 17L15 12L10 7" :stroke="fill" stroke-width="1.5" />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    direction: {
      type: String,
      validator: (value) => ['up', 'down', 'left', 'right'].includes(value),
      default: 'down',
    },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
