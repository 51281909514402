<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.2075 4.95894C19.6721 5.25766 19.8065 5.87639 19.5078 6.34092L11.4141 18.9273C11.0144 19.5489 10.1278 19.6074 9.64993 19.0438L4.90394 13.4468C4.54675 13.0256 4.59867 12.3945 5.0199 12.0373C5.44114 11.6802 6.07217 11.7321 6.42936 12.1533L10.3893 16.8233L17.8256 5.25918C18.1243 4.79465 18.743 4.66023 19.2075 4.95894Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
