<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 21.5C9 20.9477 9.44772 20.5 10 20.5H15C15.5523 20.5 16 20.9477 16 21.5C16 22.0523 15.5523 22.5 15 22.5H10C9.44772 22.5 9 22.0523 9 21.5Z"
      :fill="fill"
    />
    <path
      d="M22.8322 20.5544C23.1384 20.0948 23.0141 19.474 22.5544 19.1678C22.0948 18.8616 21.474 18.9859 21.1678 19.4456L19.912 21.3306L19.7697 21.1598C19.4162 20.7356 18.7856 20.6782 18.3613 21.0318C17.937 21.3853 17.8797 22.0159 18.2332 22.4402L18.9472 23.2971C19.5179 23.9819 20.587 23.9247 21.0812 23.1828L22.8322 20.5544Z"
      :fill="fill"
    />
    <path
      d="M9 14C9 13.4477 9.44772 13 10 13H15C15.5523 13 16 13.4477 16 14C16 14.5523 15.5523 15 15 15H10C9.44772 15 9 14.5523 9 14Z"
      :fill="fill"
    />
    <path
      d="M22.8322 13.0544C23.1384 12.5948 23.0141 11.974 22.5544 11.6678C22.0948 11.3616 21.474 11.4859 21.1678 11.9456L19.912 13.8306L19.7697 13.6598C19.4162 13.2356 18.7856 13.1782 18.3613 13.5318C17.937 13.8853 17.8797 14.5159 18.2332 14.9402L18.9472 15.7971C19.5179 16.4819 20.587 16.4247 21.0812 15.6828L22.8322 13.0544Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3333 3.5H12.6239C13.2549 2.59338 14.3046 2 15.4929 2H16.5071C17.6954 2 18.7451 2.59338 19.3761 3.5H20.6667C21.403 3.5 22 4.09695 22 4.83333V5H24C25.6569 5 27 6.34315 27 8V27C27 28.6569 25.6569 30 24 30H8C6.34315 30 5 28.6569 5 27V8C5 6.34315 6.34315 5 8 5H10V4.83333C10 4.09695 10.597 3.5 11.3333 3.5ZM17.7345 4.64245L18.3313 5.5H20V6.5H12V5.5H13.6687L14.2655 4.64245C14.5386 4.25006 14.9867 4 15.4929 4H16.5071C17.0133 4 17.4614 4.25006 17.7345 4.64245ZM22 7V7.16667C22 7.90305 21.403 8.5 20.6667 8.5H11.3333C10.597 8.5 10 7.90305 10 7.16667V7H8C7.44772 7 7 7.44772 7 8V27C7 27.5523 7.44772 28 8 28H24C24.5523 28 25 27.5523 25 27V8C25 7.44771 24.5523 7 24 7H22Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
