<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.2929 10.0397L16 10.7468L17.4142 9.33263L16.7071 8.62553L12.9428 4.86123C12.4221 4.34053 11.5779 4.34053 11.0572 4.86123L7.29292 8.62553L6.58582 9.33263L8.00003 10.7468L8.70714 10.0397L12 6.74685L15.2929 10.0397ZM15.2929 13.9595L16 13.2524L17.4142 14.6666L16.7071 15.3737L12.9428 19.138C12.4221 19.6587 11.5779 19.6587 11.0572 19.138L7.29292 15.3737L6.58582 14.6666L8.00003 13.2524L8.70714 13.9595L12 17.2524L15.2929 13.9595Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
