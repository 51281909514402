<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.36225 6.02859C4.6226 5.76824 5.04471 5.76824 5.30506 6.02859L8.00033 8.72385L10.6956 6.02859C10.9559 5.76824 11.378 5.76824 11.6384 6.02859C11.8987 6.28894 11.8987 6.71105 11.6384 6.9714L8.47644 10.1334C8.21348 10.3963 7.78714 10.3963 7.52421 10.1334L4.36225 6.9714C4.1019 6.71105 4.1019 6.28894 4.36225 6.02859Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
